<template>
	<!-- Home Banner -->
	<section class="banner-section">
		<div class="container">
			<div class="row align-items-center" style="height: 610px">
				<div class="col-lg-6">
					<div class="banner-content aos pb-2" data-aos="fade-up">
						<h1>
							Our <span>Clinical Pharmacists</span>
							Make Meds Manageable.
						</h1>
						<p>
							Explore Consana Health&rsquo;s Virtual Pharmacy, where you can
							connect directly with our expert, board-certified clinical
							pharmacists. Enjoy the convenience of accessing their services
							from the comfort of your home via phone, and the best part? It's
							absolutely free for you.
						</p>
						<div>
						<router-link to="/contact-us" class="btn"
							>Schedule a Consult</router-link
						>
							<img
								src="@/assets/img/down-arrow-img.png"
								class="banner-arrow-img img-fluid"
								alt="Image"
							/>
						</div>
					</div>
					<div class="my-4">
						<a style="text-decoration: underline" href="#Ask"
							>Ask your health plan or provider about the availability of this
							clinical service.</a
						>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="banner-img aos" data-aos="fade-up">
						<img
							src="@/assets/img/pharm_left_banner.png"
							class="img-fluid"
							alt="Image"
						/>
						<!--img
							src="@/assets/img/bot_group.svg"
							class="img-fluid"
							style="max-width: 480px"
							alt="Image"
						/-->
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Home Banner -->
	<!-- Specialities Section 
	<section class="specialities-section-one">
		<div class="container">
			<div class="row">
				<div class="col-md-6 aos" data-aos="fade-up">
					<div class="section-header-one section-header-slider">
						<h2 class="section-title">Specialities</h2>
					</div>
				</div>
				<div class="col-md-6 aos" data-aos="fade-up">
					<div class="owl-nav slide-nav-1 text-end nav-control"></div>
				</div>
			</div>
			<div
				class="owl-carousel specialities-slider-one owl-theme aos"
				data-aos="fade-up"
			>
				<Carousel
					:wrap-around="true"
					:settings="settings"
					:breakpoints="breakpoints"
				>
					<Slide v-for="item in IndexBanner" :key="item.id">
						<div class="item slide-content-container">
							<div class="specialities-item">
								<div class="specialities-img">
									<span
										><img
											:src="require(`@/assets/img/specialities/${item.Image}`)"
											alt="specialities"
									/></span>
								</div>
								<p>{{ item.Title }}</p>
							</div>
						</div>
					</Slide>
					<template #addons>
						<Navigation />
					</template>
				</Carousel>
			</div>
			<div class="specialities-btn aos" data-aos="fade-up">
				<router-link to="/search" class="btn">
					See All Specialities
				</router-link>
			</div>
		</div>
	</section>-->
	<!-- /Specialities Section -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
// import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexBanner from "@/assets/json/indexbanner.json";
import "vue3-carousel/dist/carousel.css";

import { ref } from "vue";
const currentDate = ref(new Date());
export default {
	data() {
		return {
			startdate: currentDate,
			IndexBanner: IndexBanner,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				767: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				991: {
					itemsToShow: 3,
					snapAlign: "center",
				},
				1024: {
					itemsToShow: 6,
					snapAlign: "start",
				},
			},
		};
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
