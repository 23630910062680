<template>
	<!-- Articles Section -->
	<section class="articles-section">
		<div class="container">
			<div v-if="showSignup" class="row mb-5 justify-content-center">
				<div class="col-md-12 aos" data-aos="fade-up">
					<div class="section-header-one text-center mb-2">
						<h2 class="section-title">Stay Connected</h2>
						<p>Sign up to get interesting news and updates from<br/>Consana Health delivered to your inbox by entering your information below</p>	
					</div>
				</div>
				<div class="col-12 mb-5 aos" data-aos="fade-up">
					<iframe class="w-100" style="min-height:550px" src="https://cdn.forms-content.sg-form.com/1ade4e3e-016d-11ea-a178-f6f6afa996f7"/>
				</div>
			</div>
			<div v-if="!hideArticles" class="row">
				<div class="col-md-12 aos" data-aos="fade-up">
					<div class="section-header-one text-center">
						<h2 class="section-title">Latest Articles</h2>
					</div>
				</div>
			</div>
			<div v-if="!hideArticles" class="row">
				<div
					class="col-lg-6 col-md-6 d-flex aos"
					data-aos="fade-up"
					v-for="item in IndexArticles"
					:key="item.id"
				>
					<div class="articles-grid w-100">
						<div class="articles-info">
							<div class="articles-left">
								<router-link to="/blog-details">
									<div class="articles-img">
										<img
											:src="require(`@/assets/img/blog/${item.Image}`)"
											class="img-fluid"
											alt="Image"
										/>
									</div>
								</router-link>
							</div>
							<div class="articles-right">
								<div class="articles-content">
									<ul class="articles-list nav">
										<li><i class="feather-user"></i> {{ item.Name }}</li>
										<li><i class="feather-calendar"></i> {{ item.Day }}</li>
									</ul>
									<h4>
										<router-link to="/blog-details">{{
											item.Title
										}}</router-link>
									</h4>
									<p>{{ item.Content }}</p>
									<router-link to="/blog-details" class="btn"
										>View More</router-link
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Articles Section -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexArticles from "@/assets/json/indexarticles.json";
export default {
	props: {
		showSignup: {
			type: Boolean,
			default: false,
    },
    hideArticles: {
      type: Boolean,
      default: false,
    },
	},
	data() {
		return {
			IndexArticles: IndexArticles,
		};
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
