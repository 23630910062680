<template>
  <!-- Footer -->
  <footer class="footer footer-one">
    <div class="footer-top aos" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-about">
              <div class="footer-logo mb-1">
                <img src="@/assets/img/logo.svg" alt="logo" />
              </div>
              <div class="footer-about-content" style="text-align: justify">
                <p>
                  Delivering meaningful interactions between patients,
                  pharmacists and healthcare providers through cutting-edge
                  technology and clinical guidance.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <!-- div class="col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Solutions</h2>
                  <ul>
                    <li>
                      <router-link to="/medication-therapy-management"
                        >Medication Therapy Management</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/comprehensive-medication-management"
                        >Comprehensive Medication Management</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div-->
              <div class="col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Why Consana</h2>
                  <ul>
                    <li>
                      <router-link to="/">For Patients &amp; Caregivers</router-link>
                    </li>
                    <li>
                      <router-link to="/for-payors">For Payers</router-link>
                    </li>
                    <!-- li>
                      <router-link to="/for-health-systems"
                        >For Health Systems</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/for-pharmacists"
                        >For Pharmacists</router-link
                      >
                    </li-->
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Company</h2>
                  <ul>
                    <!--li>
                      <router-link to="/blog-grid">Blog</router-link>
                    </li>
                    <li>
                      <router-link to="/about-us">Our Story</router-link>
                    </li>
                    <li>
                      <router-link to="/our-culture"
                        >Culture & Careers</router-link
                      >
                    </li-->
                    <li><router-link to="/contact-us">Contact</router-link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-7">
            <div class="footer-widget">
              <h2 class="footer-title">Follow Us</h2>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/consanahealth" target="_blank">
                      <i class="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/consana-health" target="_blank">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  Copyright © {{ new Date().getFullYear() }}
                  Consana Health Services, LLC. All Rights Reserved.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <router-link to="/privacy-policy">Privacy Policy</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <scroll-to-top />
  </footer>
  <!-- /Footer -->
</template>
<script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  export default {
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
  };
</script>
