<template>
	<!-- App Section -->
	<section class="app-section">
		<div class="container">
			<div class="app-bg">
				<div class="row align-items-center">
					<div class="col-lg-6 col-md-12">
						<div class="app-content">
							<div class="app-header aos" data-aos="fade-up">
								<h5>Working for Your Better Health.</h5>
								<h2>Download the Consana App today!</h2>
							</div>
							<div class="app-scan aos" data-aos="fade-up">
								<p>Scan the QR code to get the app now</p>
								<img src="@/assets/img/scan-img.png" alt="Image" />
							</div>
							<div class="google-imgs aos" data-aos="fade-up">
								<a href="javascript:void(0);"
									><img src="@/assets/img/google-play.png" alt="img"
								/></a>
								<a href="javascript:void(0);"
									><img src="@/assets/img/app-store.png" alt="img"
								/></a>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-12 aos" data-aos="fade-up">
						<div class="mobile-img">
							<img
								src="@/assets/img/mobile-img.png"
								class="img-fluid"
								alt="img"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /App Section -->
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexApp from "@/assets/json/indexapp.json";
export default {
	data() {
		return {
			IndexApp: IndexApp,
		};
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
