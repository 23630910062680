import axios from 'axios'

const apiClient = axios.create({
  baseURL: '/api',
  timeout: 5000, // indicates, 5000ms ie. 5 second
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
    // 'Access-Control-Allow-Origin': '*'
  },
})

export default apiClient