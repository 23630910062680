<template>
	<!-- Work Section -->
	<section :class="`work-section ${cssClass}`">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-12 work-img-info aos" data-aos="fade-up">
					<div class="work-img">
						<img
							src="@/assets/img/pharm_point_right.png"
							class="img-fluid"
							alt="Image"
						/>
					</div>
				</div>
				<div class="col-lg-8 col-md-12 work-details">
					<div class="section-header-one aos" data-aos="fade-up">
						<h5>How it Works</h5>
						<h2 class="section-title">Easy steps to better health</h2>
						<p>
							During your first 30-45 minute medication phone consultation with
							your Consana pharmacist the pharmacist will:
						</p>
					</div>
					<div class="row">
						<div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
							<div class="work-info">
								<div class="work-icon">
									<span
										><img src="@/assets/img/icons/choose-icon3.svg" width="35" alt="Image"
									/></span>
								</div>
								<div class="work-content">
									<span
										><strong>Review all your current medications</strong> and
										any new prescriptions for safety and effectiveness.</span
									>
								</div>
							</div>
							<div class="work-info">
								<div class="work-icon">
									<span
										><img src="@/assets/img/icons/success-tick.svg" width="35" alt="Image"
									/></span>
								</div>
								<div class="work-content">
									<span
										><strong>Discuss your health goals</strong> and ensure your
										medications will help you achieve them.</span
									>
								</div>
							</div>
							<div class="work-info">
								<div class="work-icon">
									<span
										><img src="@/assets/img/icons/work-04.svg" width="35" alt="Image"
									/></span>
								</div>
								<div class="work-content">
									<p>
										<strong>Address any questions or concerns</strong> you have
										about your medications.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-6 aos" data-aos="fade-up">
							<div class="work-info">
								<div class="work-icon">
									<span
										><img src="@/assets/img/icons/work-01.svg" width="35" alt="Image"
									/></span>
								</div>
								<div class="work-content">
									<p>
										<strong>Review the purpose of each medication</strong> you
										are taking, the intended effects and potential side effects,
										and any possible interactions you may experience.
									</p>
								</div>
							</div>
							<div class="work-info">
								<div class="work-icon">
									<span
										><img src="@/assets/img/icons/work-03.svg" width="35" alt="Image"
									/></span>
								</div>
								<div class="work-content">
									<p>
										<strong>Provide you with a medication action plan</strong>
										that will include all your current medications as well as
										tips, recommendations, and cost-saving opportunities.
									</p>
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12 aos mb-5" data-aos="fade-up">
							<p>
								Following your first medication consultation, Consana will
								schedule routine check-ins and work with you as needed to keep
								you on track with your medication plan and health goals.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Work Section -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
	props: {
		cssClass: {
			type: String,
			default: null,
		},
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
