<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title" :text="text" />

		<div class="page-wrapper">
			<!-- Page Content -->
			<section class="content">
				<div class="container">
					<div class="row">
						<div class="col-lg-8 col-md-12">
							<div class="row blog-grid-row">
								<div
									class="col-md-6 col-sm-12"
									v-for="item in Blogs"
									:key="item.id"
								>
									<!-- Blog Post -->
									<div class="blog grid-blog">
										<div v-if="item.Image" class="blog-image">
											<router-link to="/blog-details"
												><img
													class="img-fluid"
													:src="require(`@/assets/img/blog/${item.Image}`)"
													alt="Post Image"
											/></router-link>
										</div>
										<div class="blog-content">
											<ul class="entry-meta meta-item">
												<li>
													<div class="post-author">
														<img
															v-if="item.Author?.Image"
															:src="
																require(`@/assets/img/doctors/${item.Author.Image}`)
															"
															alt="Post Author"
														/>
														<span>{{ item.Author?.Name }}</span>
													</div>
												</li>
												<li>
													<i class="far fa-clock me-1"></i>{{ item.Date }}
												</li>
											</ul>
											<h3 class="blog-title">
												<router-link to="/blog-details">{{
													item.Title
												}}</router-link>
											</h3>
											<p class="mb-0">{{ item.Summary }}</p>
										</div>
									</div>
									<!-- /Blog Post -->
								</div>
							</div>
						</div>
						<blog-sidebar />
					</div>
				</div>
			</section>
			<!-- /Page Content -->
		</div>
		<page-footer />
	</div>
</template>

<script>
import Blogs from "@/assets/json/blogs.json";
export default {
	data() {
		return {
			title: "Blog Grid",
			text: "Home",
			Blogs: Blogs,
		};
	},
};
</script>
