<template>
	<!-- Blog Sidebar -->
	<div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
		<div class="stickysidebar">
			<!-- Search -->
			<div class="card search-widget">
				<div class="card-body">
					<form class="search-form">
						<div class="input-group">
							<input type="text" placeholder="Search..." class="form-control" />
							<b-button type="submit" variant="primary"
								><i class="fa fa-search"></i
							></b-button>
						</div>
					</form>
				</div>
			</div>
			<!-- /Search -->

			<!-- Latest Posts -->
			<div class="card post-widget">
				<div class="card-header">
					<h4 class="card-title">Latest Posts</h4>
				</div>
				<div class="card-body">
					<ul class="latest-posts">
						<li v-for="item in Blogs" :key="item.id">
							<div class="post-thumb">
								<router-link to="/blog-details">
									<img
										class="img-fluid"
										:src="
											require(`@/assets/img/blog/${
												item.ThumbnailImage ?? item.Image
											}`)
										"
										alt="blog-image"
									/>
								</router-link>
							</div>
							<div class="post-info">
								<h4>
									<router-link to="/blog-details">{{ item.Title }}</router-link>
								</h4>
								<p>{{ item.Date }}</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!-- /Latest Posts -->

			<!-- Categories -->
			<div class="card category-widget">
				<div class="card-header">
					<h4 class="card-title">Blog Categories</h4>
				</div>
				<div class="card-body">
					<ul class="categories">
						<li v-for="category in Categories" :key="category">
							<a href="javascript:;"
								>{{ category.Name }} <span>{{ category.Count }}</span></a
							>
						</li>
					</ul>
				</div>
			</div>
			<!-- /Categories -->

			<!-- Tags -->
			<div class="card tags-widget">
				<div class="card-header">
					<h4 class="card-title">Tags</h4>
				</div>
				<div class="card-body">
					<ul class="tags">
						<li v-for="tag in Tags" :key="tag">
							<a href="javascript:;" class="tag">{{ tag }}</a>
						</li>
					</ul>
				</div>
			</div>
			<!-- /Tags -->
		</div>
	</div>
	<!-- /Blog Sidebar -->
</template>

<script>
import Blogs from "@/assets/json/blogs.json";
export default {
	data() {
		return {
			Blogs: Blogs,
			Categories: [],
			Tags: [],
		};
	},
	computed() {},
	async mounted() {
		await this.loadBlogMetaData();
	},
	methods: {
		loadBlogMetaData() {
			this.Categories = this.getCategories(this.Blogs);
			this.Tags = this.getTags(this.Blogs);
		},
		getCategories(data) {
			let distinct = [...new Set(data.map((item) => item.Category))];
			return distinct.map((category) => this.buildCategoryCount(category));
		},
		buildCategoryCount(category) {
			return {
				Name: category,
				Count: this.Blogs.filter((x) => x.Category === category).length,
			};
		},
		getTags(data) {
			return [...new Set(data.flatMap((item) => item.Tags))];
		},
	},
};
</script>
