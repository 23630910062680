<template>
	<!-- Page Banner -->
	<section class="page-banner-section">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-8">
					<div class="page-banner-content aos" data-aos="fade-up">
						<h1 v-html="title"></h1>
						<p v-html="text"></p>
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Page Banner -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import IndexBanner from "@/assets/json/indexbanner.json";
import "vue3-carousel/dist/carousel.css";

import { ref } from "vue";
const currentDate = ref(new Date());
export default {
	props: {
		title: {
			type: String,
			default: "",
		},
		text: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			startdate: currentDate,
			IndexBanner: IndexBanner,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				767: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				991: {
					itemsToShow: 3,
					snapAlign: "center",
				},
				1024: {
					itemsToShow: 6,
					snapAlign: "start",
				},
			},
		};
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
};
</script>
