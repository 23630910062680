<template>
	<!-- Ask Section -->
	<section class="ask-section mb-5" id="Ask">
		<div class="container">
			<div class="row">
				<div class="col-lg-4 col-md-12 ask-img-info aos" data-aos="fade-up">
					<div class="ask-img">
						<img
							src="@/assets/img/flyer.png"
							class="img-fluid"
							style="max-height: 320px"
							alt="img"
						/>
					</div>
				</div>
				<div class="col-lg-8 col-md-12 ask-details">
					<div class="section-header-one aos" data-aos="fade-up">
						<h5>Ask your plan or provider about us!</h5>
						<h2 class="section-title">
							Consana is working with a growing number of health plans and health systems
						</h2>
						<p>
							Click the button below to access a frequently asked question
							flyer that you can share with your health plan and
							providers to see if you can benefit from our free service.
						</p>
						<router-link to="/contact-us" class="btn">Get the Flyer</router-link>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Ask Section -->
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexApp from "@/assets/json/indexapp.json";
export default {
	data() {
		return {
			IndexApp: IndexApp,
		};
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
