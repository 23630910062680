<template>
	<!-- Header -->
	<header
		class="header header-fixed"
		:class="{ 'header-one': true, 'header-space': isScrolled }"
	>
		<div class="container">
			<nav class="navbar navbar-expand-lg header-nav">
				<div class="navbar-header">
					<a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
						<span class="bar-icon">
							<span></span>
							<span></span>
							<span></span>
						</span>
					</a>
					<router-link to="/" class="navbar-brand logo">
						<img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
					</router-link>
				</div>
				<div class="main-menu-wrapper">
					<div class="menu-header">
						<router-link to="/" class="menu-logo">
							<img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
						</router-link>
						<a
							id="menu_close"
							class="menu-close"
							href="javascript:void(0);"
							@click="closeSidebar"
						>
							<i class="fas fa-times"></i>
						</a>
					</div>
					<main-nav></main-nav>
				</div>
			</nav>
			<div
				class="sidebar-overlay"
				:class="{ opened: isSidebarOpen }"
				@click="closeSidebar"
			></div>
		</div>
	</header>
	<!-- /Header -->
</template>
<script>
export default {
	data() {
		return {
			isScrolled: false,
			isSidebarOpen: false,
			isVisible: false,
		};
	},
	mounted() {
		// Add a scroll event listener when the component is mounted
		window.addEventListener("scroll", this.handleScroll);
	},
	beforeUnmount() {
		// Remove the scroll event listener when the component is about to be unmounted
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll() {
			// Get the scroll position
			const scroll = window.scrollY;

			// Update the isScrolled data property based on the scroll position
			this.isScrolled = scroll > 35;
		},
		toggleSidebar() {
			this.isSidebarOpen = !this.isSidebarOpen;
			document.documentElement.classList.toggle("menu-opened");
		},
		closeSidebar() {
			this.isSidebarOpen = false;
			document.documentElement.classList.remove("menu-opened");
		},
		toggleElement() {
			this.isVisible = !this.isVisible;
		},
	},
};
</script>
