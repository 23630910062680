<template>
	<!-- Statistics Section -->
	<section class="statistics-section">
		<div class="container">
			<div class="row">
				<div class="row-fluid-wrapper row-depth-1 row-number-14 dnd-section">
					<div class="row-fluid ">
						<div class="span12 widget-span widget-type-cell dnd-column" style="" data-widget-type="cell"
							data-x="0" data-w="12">
							<div class="row-fluid-wrapper row-depth-1 row-number-15 dnd-row">
								<div class="row-fluid ">
									<div class="span12 widget-span widget-type-custom_widget dnd-module" style=""
										data-widget-type="custom_widget" data-x="0" data-w="12">
										<div id="hs_cos_wrapper_widget_1663906660144"
											class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style=""
											data-hs-cos-general-type="widget" data-hs-cos-type="module">
											<div
												class="sr-multicol-media sr-two-col-02 widget_1663906660144  bg-primary sr-padding-custom text-auto  prototype-no-background">
												<div class="container">
													<div class="row sr-row sr-valign-center">
														<div class="col-md-4 sr-col text-center text-white sr-repeater-1">
															<div class="content-wrapper">
																<div class="col-content ">
																	<h2
																		style="font-size: 65px; margin-bottom: 0!important;">
																		22<span
																			style="color: #1f3056; font-size: 40px;"><strong>%</strong></span>
																	</h2>
																	<p>relative reduction in 30-day all-cause readmission
																		rate</p>
																</div>
															</div>
														</div>
														<div class="col-md-4 sr-col text-center text-white sr-repeater-2">
															<div class="content-wrapper">
																<div class="col-content ">
																	<h2
																		style="font-size: 65px; margin-bottom: 0!important;">
																		50-80<span
																			style="color: #1f3056; font-size: 40px;"><strong>%</strong></span>
																	</h2>
																	<p>engagement by patients<br><br></p>
																</div>
															</div>
														</div>
														<div class="col-md-4 sr-col text-center text-white sr-repeater-3">
															<div class="content-wrapper">
																<div class="col-content ">
																	<h2
																		style="font-size: 65px; margin-bottom: 0!important;">
																		55-76<span
																			style="font-size: 40px; color: #1f3056;"><strong>%</strong></span>
																	</h2>
																	<p>adoption of pharmacist recommendations by providers
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row-fluid-wrapper row-depth-1 row-number-16 dnd-row">
								<div class="row-fluid ">
									<div class="span12 widget-span widget-type-custom_widget dnd-module" style=""
										data-widget-type="custom_widget" data-x="0" data-w="12">
										<div id="hs_cos_wrapper_module_1665436454255"
											class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style=""
											data-hs-cos-general-type="widget" data-hs-cos-type="module">
											<div
												class="sr-multicol-media sr-two-col-02 module_1665436454255  bg-primary sr-padding-custom text-auto  prototype-no-background">
												<div class="container">
													<div class="row sr-row sr-valign-center">
														<div class="col-md-4 sr-col text-center text-white sr-repeater-1">
															<div class="content-wrapper">
																<div class="col-content ">
																	<h2
																		style="font-size: 65px; margin-bottom: 0!important;">
																		4 <span
																			style="color: #1f3056; font-size: 40px;"><strong>&amp;</strong></span>
																		5</h2>
																	<p>Star medication reconciliation completion performance
																	</p>
																</div>
															</div>
														</div>
														<div class="col-md-4 sr-col text-center text-white sr-repeater-2">
															<div class="content-wrapper">
																<div class="col-content ">
																	<h2
																		style="font-size: 65px; margin-bottom: 0!important;">
																		9.3<span
																			style="font-size: 40px; color: #1f3056;"><strong>%</strong></span>
																	</h2>
																	<p>increase in adherence to maintenance medications</p>
																</div>
															</div>
														</div>
														<div class="col-md-4 sr-col text-center text-white sr-repeater-3">
															<div class="content-wrapper">
																<div class="col-content ">
																	<h2
																		style="font-size: 65px; margin-bottom: 0!important;">
																		19.2<span
																			style="color: #1f3056; font-size: 40px;"><strong>%</strong></span>
																	</h2>
																	<p>increase in appropriate statin use<br><br></p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row-fluid-wrapper row-depth-1 row-number-17 dnd-row">
								<div class="row-fluid ">
									<div class="span12 widget-span widget-type-custom_widget dnd-module" style=""
										data-widget-type="custom_widget" data-x="0" data-w="12">
										<div id="hs_cos_wrapper_widget_1664468512972"
											class="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style=""
											data-hs-cos-general-type="widget" data-hs-cos-type="module">
											<div
												class="sr-cards-slider-01 widget_1664468512972  sr-padding-custom prototype-no-background ">
												<div class="container content-wrapper">
													<div class="row align-items-end">

														<div class="col-lg-5">
															<div class="bg-custom text-auto ">
																<h2 class="heading h3">Reduce Total Cost of Care. Improve
																	Quality Performance. Increase Patient Satisfaction.
																</h2>
																<div class="description sr-spacer-bottom-100">
																	<p>Use Consana to flip the script on how your pharmacy
																		organization is viewed internally: from a cost
																		center to a value center.</p>
																	<p style="margin-bottom: 0px;"><strong>Here’s how it all
																			works:</strong></p>
																</div>
															</div>
														</div>
														<div class="col-lg-7">
															<div
																class="feat-slider slick-initialized slick-slider slick-dotted">

																<div class="slick-list draggable">
																	<div class="slick-track"
																		style="opacity: 1; width: 4452px; transform: translate3d(-954px, 0px, 0px);">
																		<div class="slick-slide slick-cloned"
																			data-slick-index="-2" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Boost.svg"
																					alt="Boost Quality Measures"
																					loading="lazy" width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Boost Quality Measures</h4>
																				<p style="margin-bottom: 0px;">Like SUPD and
																					SPC statin gaps, MTM CMR,
																					quadruple-weighted CAHPS and
																					triple-weighted medication adherence
																					through adherence coaching, meds
																					synchronization, increased
																					home-delivery, and longer month
																					supplies.</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-cloned"
																			data-slick-index="-1" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Value-Based-Pricing.svg"
																					alt="Value-Based Pricing" loading="lazy"
																					width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Value-Based Pricing</h4>
																				<p style="margin-bottom: 0px;">Only pay for
																					members Consana engages. Or go even
																					further to explore pay-for-performance
																					based pricing with full capped risk,
																					upside and downside. We’re willing to
																					get paid based on outcomes because we’ve
																					already proven results.</p>
																			</div>
																		</div>
																		<div class="slick-slide" data-slick-index="0"
																			aria-hidden="true" tabindex="-1" role="tabpanel"
																			id="slick-slide00"
																			aria-describedby="slick-slide-control00"
																			style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Readmissions.svg"
																					alt="Optimize Medication Management"
																					loading="lazy" width="45" height="45">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Optimize Medication Management</h4>
																				<p style="margin-bottom: 0px;">Less than
																					comprehensive meds management
																					post-discharge drives up readmissions.
																				</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-current slick-active"
																			data-slick-index="1" aria-hidden="false"
																			tabindex="0" role="tabpanel" id="slick-slide01"
																			aria-describedby="slick-slide-control01"
																			style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Connect.svg"
																					alt="Connect" loading="lazy" width="60"
																					height="60">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Connect Within 72 Hours</h4>
																				<p style="margin-bottom: 0px;">It’s a big
																					job, but connecting with patients soon
																					after discharge can significantly reduce
																					hospital readmissions.</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-active"
																			data-slick-index="2" aria-hidden="false"
																			tabindex="0" role="tabpanel" id="slick-slide02"
																			aria-describedby="slick-slide-control02"
																			style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Pharmacists.svg"
																					alt="Clinical Pharmacists Make the Difference"
																					loading="lazy" width="45" height="45">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Clinical Pharmacists Make the Difference
																				</h4>
																				<p style="margin-bottom: 0px;">Consana
																					leverages the most trusted professionals
																					in healthcare: clinical pharmacists who
																					connect with patients and problem-solve
																					medication issues.</p>
																			</div>
																		</div>
																		<div class="slick-slide" data-slick-index="3"
																			aria-hidden="true" tabindex="-1" role="tabpanel"
																			id="slick-slide03"
																			aria-describedby="slick-slide-control03"
																			style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Med-Plan.svg"
																					alt="Check the Box on Transitions of Care &amp; More"
																					loading="lazy" width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Check the Box on Transitions of Care
																					&amp; More</h4>
																				<p style="margin-bottom: 0px;">Each MRP
																					produces a medication action plan and
																					reconciled medication list, identifies
																					drug therapy problems, makes clinical
																					recommendations, <em>and</em> counts as
																					your patient engagement visit
																					post-discharge. Supplemental file
																					delivery included.</p>
																			</div>
																		</div>
																		<div class="slick-slide" data-slick-index="4"
																			aria-hidden="true" tabindex="-1" role="tabpanel"
																			id="slick-slide04"
																			aria-describedby="slick-slide-control04"
																			style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Boost.svg"
																					alt="Boost Quality Measures"
																					loading="lazy" width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Boost Quality Measures</h4>
																				<p style="margin-bottom: 0px;">Like SUPD and
																					SPC statin gaps, MTM CMR,
																					quadruple-weighted CAHPS and
																					triple-weighted medication adherence
																					through adherence coaching, meds
																					synchronization, increased
																					home-delivery, and longer month
																					supplies.</p>
																			</div>
																		</div>
																		<div class="slick-slide" data-slick-index="5"
																			aria-hidden="true" tabindex="-1" role="tabpanel"
																			id="slick-slide05"
																			aria-describedby="slick-slide-control05"
																			style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Value-Based-Pricing.svg"
																					alt="Value-Based Pricing" loading="lazy"
																					width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Value-Based Pricing</h4>
																				<p style="margin-bottom: 0px;">Only pay for
																					members Consana engages. Or go even
																					further to explore pay-for-performance
																					based pricing with full capped risk,
																					upside and downside. We’re willing to
																					get paid based on outcomes because we’ve
																					already proven results.</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-cloned"
																			data-slick-index="6" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Readmissions.svg"
																					alt="Optimize Medication Management"
																					loading="lazy" width="45" height="45">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Optimize Medication Management</h4>
																				<p style="margin-bottom: 0px;">Less than
																					comprehensive meds management
																					post-discharge drives up readmissions.
																				</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-cloned"
																			data-slick-index="7" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Connect.svg"
																					alt="Connect" loading="lazy" width="60"
																					height="60">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Connect Within 72 Hours</h4>
																				<p style="margin-bottom: 0px;">It’s a big
																					job, but connecting with patients soon
																					after discharge can significantly reduce
																					hospital readmissions.</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-cloned"
																			data-slick-index="8" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Pharmacists.svg"
																					alt="Clinical Pharmacists Make the Difference"
																					loading="lazy" width="45" height="45">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Clinical Pharmacists Make the Difference
																				</h4>
																				<p style="margin-bottom: 0px;">Consana
																					leverages the most trusted professionals
																					in healthcare: clinical pharmacists who
																					connect with patients and problem-solve
																					medication issues.</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-cloned"
																			data-slick-index="9" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Med-Plan.svg"
																					alt="Check the Box on Transitions of Care &amp; More"
																					loading="lazy" width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Check the Box on Transitions of Care
																					&amp; More</h4>
																				<p style="margin-bottom: 0px;">Each MRP
																					produces a medication action plan and
																					reconciled medication list, identifies
																					drug therapy problems, makes clinical
																					recommendations, <em>and</em> counts as
																					your patient engagement visit
																					post-discharge. Supplemental file
																					delivery included.</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-cloned"
																			data-slick-index="10" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Boost.svg"
																					alt="Boost Quality Measures"
																					loading="lazy" width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Boost Quality Measures</h4>
																				<p style="margin-bottom: 0px;">Like SUPD and
																					SPC statin gaps, MTM CMR,
																					quadruple-weighted CAHPS and
																					triple-weighted medication adherence
																					through adherence coaching, meds
																					synchronization, increased
																					home-delivery, and longer month
																					supplies.</p>
																			</div>
																		</div>
																		<div class="slick-slide slick-cloned"
																			data-slick-index="11" id="" aria-hidden="true"
																			tabindex="-1" style="width: 282px;">
																			<div>
																				<img src="https://www.consanahealth.com/hubfs/images/Website%202022/Payers/Consana-Payers-Page-Icon-Value-Based-Pricing.svg"
																					alt="Value-Based Pricing" loading="lazy"
																					width="50" height="50">
																			</div>
																			<div>
																				<h6></h6>
																			</div>
																			<div>
																				<h4>Value-Based Pricing</h4>
																				<p style="margin-bottom: 0px;">Only pay for
																					members Consana engages. Or go even
																					further to explore pay-for-performance
																					based pricing with full capped risk,
																					upside and downside. We’re willing to
																					get paid based on outcomes because we’ve
																					already proven results.</p>
																			</div>
																		</div>
																	</div>
																</div>
																<ul class="slick-dots" style="" role="tablist">
																	<li class="" role="presentation"><button type="button"
																			role="tab" id="slick-slide-control00"
																			aria-controls="slick-slide00"
																			aria-label="1 of 3" tabindex="-1">1</button>
																	</li>
																	<li role="presentation" class="slick-active"><button
																			type="button" role="tab"
																			id="slick-slide-control01"
																			aria-controls="slick-slide01"
																			aria-label="2 of 3" tabindex="0"
																			aria-selected="true">2</button></li>
																	<li role="presentation" class=""><button type="button"
																			role="tab" id="slick-slide-control02"
																			aria-controls="slick-slide02"
																			aria-label="3 of 3" tabindex="-1">3</button>
																	</li>
																	<li role="presentation" class=""><button type="button"
																			role="tab" id="slick-slide-control03"
																			aria-controls="slick-slide03"
																			aria-label="4 of 3" tabindex="-1">4</button>
																	</li>
																	<li role="presentation" class=""><button type="button"
																			role="tab" id="slick-slide-control04"
																			aria-controls="slick-slide04"
																			aria-label="5 of 3" tabindex="-1">5</button>
																	</li>
																	<li role="presentation" class=""><button type="button"
																			role="tab" id="slick-slide-control05"
																			aria-controls="slick-slide05"
																			aria-label="6 of 3" tabindex="-1">6</button>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import IndexApp from "@/assets/json/indexapp.json";
export default {
	data() {
		return {
			IndexApp: IndexApp,
		};
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
