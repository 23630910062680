<template>
	<!-- Testimonial Section -->
	<section class="testimonial-section">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="testimonial-slider slick">
						<Carousel
							:wrap-around="true"
							:settings="settings"
							:breakpoints="breakpoints"
						>
							<Slide v-for="item in IndexTestimonial" :key="item.id">
								<div class="testimonial-grid">
									<div class="testimonial-info p-5">
										<div class="testimonial-content">
											<div class="section-header section-inner-header testimonial-header">
												<h5 v-if="item.IsTestimonial">Patient Feedback</h5>
												<h5 v-else>Patient Success Story</h5>
											</div>
											<div class="testimonial-details">
												<p>
													<strong v-if="item.IsTestimonial">&ldquo;</strong>{{ item.Content
													}}<strong v-if="item.IsTestimonial">&rdquo;</strong>
												</p>
												<h6>
													<span>{{ item.Name }}</span> {{ item.Location }}
												</h6>
											</div>
										</div>
									</div>
								</div>
							</Slide>
							<template #addons>
								<Navigation />
							</template>
						</Carousel>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Testimonial Section -->
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import IndexTestimonial from "@/assets/json/indextestimonial.json";
import "vue3-carousel/dist/carousel.css";

export default {
	data() {
		return {
			IndexTestimonial: IndexTestimonial,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				767: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				991: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				1024: {
					itemsToShow: 1,
					snapAlign: "start",
				},
			},
		};
	},
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
	components: {
		Carousel,
		Slide,
		Navigation,
	},
};
</script>
