<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title">
			<h5>Click the link below to view our NOPP in accordance with HIPAA.</h5>
			<p><b-button v-if="!showPrivacy"
					type="submit"
					class="btn btn-primary prime-btn"
					@click="handleShowPrivacy"
					>View Privacy Policy</b-button
				><b-button v-else type="submit"
					class="btn btn-primary prime-btn"
					@click="handleHidePrivacy"
					>Hide Privacy Policy</b-button
				></p>
			<h5>Click the link below to view our Website Privacy Policy.</h5>
			<p><b-button v-if="!showWebPrivacy"
					type="submit"
					class="btn btn-primary prime-btn"
					@click="handleShowWebPrivacy"
					>View Website Privacy
							Policy</b-button
				><b-button v-else type="submit"
					class="btn btn-primary prime-btn"
					@click="handleHideWebPrivacy">
	Hide Website Privacy
	Policy
</b-button></p>
		</page-banner>
    <div class="page-wrapper">
      <template v-if="showWebPrivacy">
        <div id="web-privacy" class="p-5">
          <div class=WordSection1>
            <h1>Consana Health Services, LLC Privacy Policy</h1>
            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;
margin-left:0in;text-indent:0in;line-height:107%'>
              <b>
                <i>
                  <span style='font-family:
"Arial",sans-serif;color:#666666'>Effective Date</span>
                </i>
              </b><i>
                <span style='font-family:"Arial",sans-serif;color:#666666'>: 09/01/2024</span>
              </i>
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:33.0pt;
margin-left:0in;text-indent:0in;line-height:107%'>
              Consana Health Services, LLC
              (“Consana Health,” “we,” “us,” “our”) respects your privacy, and we are
              committed to protecting the personal information we collect from and about you.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:17.8pt;
margin-left:.5pt'>
              This Privacy Policy describes our practices with respect to
              information we collect from and about you when you visit our website, consanahealth.com,
              or any other websites that we operate and that include a link to this Privacy
              Policy (collectively, the “Websites”), or when you use software applications
              provided by Consana Health to its customers (collectively, the “Licensed
              Application”), either as an employee or representative of a Consana Health
              customer who has been authorized to use the Licensed Application on that
              customer’s behalf, or as a representative of Consana Health.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:17.8pt;
margin-left:.7pt;text-indent:-.7pt;line-height:135%'>
              This Privacy Policy does
              not apply information that we collect, receive, transmit, or otherwise process
              on behalf of Consana Health’s customers under our agreements with those
              customers, including without limitation patient data, medication and
              prescription history data, and messages transmitted through the Licensed
              Application. Our collection, use, and disclosure of this information is
              governed by those customer agreements. If you have any questions regarding a
              customer’s use of this information, please contact that customer directly. We
              are not responsible for the privacy or data security practices of our
              customers, which may differ from those described in this Privacy Policy.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:17.8pt;
margin-left:.7pt;text-indent:-.7pt;line-height:135%'>
              Please read this policy
              carefully to understand our policies and practices regarding your information
              and how we will treat it. If you do not agree with our policies and practices,
              do not access the Websites or download, register for, or use the Licensed
              Application.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:0in;text-indent:0in'>
              By accessing the Websites or by downloading,
              registering for, or using the Licensed Application, you agree to this Privacy
              Policy.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:.5pt'>&nbsp;</p>

            <h2 style='margin-left:-.25pt'>The Information We Collect and How We Collect It</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:39.15pt;
margin-left:.5pt'>
              We generally collect and receive information about you in
              three ways: (1) when you voluntarily provide information to us through the
              Websites or the Licensed Application, such as when you fill out a contact form
              on the Websites, register for an account or contact us to provide support for
              the Licensed Application; (2) through automated processes that collect
              information about your use of the Websites and the Licensed Application, and
              (3) when we receive information about you from third party sources, such as a Consana
              Health customer or publicly-available sources. These methods, and the
              information we collect, are described in more detail below.
            </p>

            <h2 style='margin-top:0in;margin-right:0in;margin-bottom:24.75pt;margin-left:
-.25pt'>
              Information you choose to provide
            </h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:18.5pt;
margin-left:.5pt'>
              When you use the Websites or the Licensed Application, we may
              collect various kinds of information that you choose to provide.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>When you fill out a contact form on the Websites, such as to
              request a product demo or submit an inquiry about our company or our offerings,
              we will collect personal information that you choose to provide in the form,
              which may include your name, your email address, and the company you represent.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>When you register for an account to use the Licensed Application,
              we will collect personal information including but not limited to your name,
              email address, account credentials, birth date, location of work, medical
              degree status, position in institution of employment, area of specialty,
              medical school or training institution(s), year of graduation, and/or
              institutional affiliations.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:39.15pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>When you communicate with Consana Health through the Websites or
              about the Licensed Application, for instance by contacting us for support, we
              will collect any information you provide as part of that communication.
            </p>

            <h2 style='margin-top:0in;margin-right:0in;margin-bottom:24.75pt;margin-left:
-.25pt'>
              Information We Collect Automatically
            </h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:18.5pt;
margin-left:.5pt'>
              When you access the Websites or download, access, and use the
              Licensed Application, we may use various technologies to automatically collect
              certain kinds of information about you and your use of the Licensed
              Application. This information may include the following:
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><b>Usage Details</b>. When you access and use the Websites and
              the Licensed Application, we may automatically collect certain details of your
              access to and use of the Websites and the Licensed Application, including
              traffic data, communication data and the features and resources that you access
              and use on or through the Websites and the Licensed Application;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><b>Device Information</b>. We may collect information about your
              device and internet connection, including the device's make and model, unique
              device identifier, IP address, operating system, browser type, network
              information, and your mobile device's telephone number.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><b>Stored Information and Files</b>. The Licensed Application
              also may access metadata and other information associated with other files
              stored on your device. This may include, for example, photographs, audio and
              video clips, personal contacts, and address book information.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:17.8pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><b>Location Information</b>. The Websites and the Licensed
              Application may collect information about your approximate location, which we
              may derive from your IP address.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:21.1pt;
margin-left:.5pt'>
              We collect some of this information through tracking
              technologies such as cookies and web beacons. For more information please see
              the “How We Use Cookies and Other Tracking Technologies” section of this
              Privacy Policy.
            </p>

            <h2 style='margin-left:-.25pt'>Information We Receive from Third Parties</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:21.1pt;
margin-left:.5pt'>
              We may receive certain information about you, including your
              name and contact details, from third parties, including the customer with whom
              you are associated and publicly available sources such as medical databases.
            </p>

            <h2 style='margin-left:-.25pt'>How We Use Your Information</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:19.15pt;
margin-left:.5pt'>
              We may use the information we collect or receive for the
              following purposes:
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:3.5pt;
margin-left:35.25pt;text-indent:-.25in;line-height:107%'>
              <span style='line-height:107%;font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>operating,
              developing, and improving the Websites and the Licensed
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:36.5pt;line-height:107%'>Application;</p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>administering subscriptions to the Licensed Application and
              performing our obligations under our agreements with Consana Health customers;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>creating aggregated and de-identified data, which we may use for
              the purposes of providing or enhancing Consana Health’s product and services
              offering; for research, analytical, statistical or benchmarking purposes; and
              for purposes of extracting, obtaining and providing information related to the
              use of the
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:4.2pt;
margin-left:36.5pt;line-height:107%'>Websites and the Licensed Application;</p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>responding to questions and communications, or obtaining your
              feedback about the websites or the Licensed Application;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>preparing and delivering announcements about features,
              functionality, terms of use, or other aspects of the Websites and the Licensed
              Application;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:3.5pt;
margin-left:35.25pt;text-indent:-.25in;line-height:107%'>
              <span style='line-height:107%;font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>analyzing
              usage trends and patterns and measuring the effectiveness of the
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:4.2pt;
margin-left:36.5pt;line-height:107%'>
              Websites and the Licensed Application and
              its features;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>with respect to information we collect through the Websites,
              offering, marketing, or advertising products, programs, and services from us,
              our affiliates, business partners, and select third parties to you, including
              through targeted advertising;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>complying with legal or regulatory requirements, judicial
              process, and our company policies (including due diligence and contracting
              activities);
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>securing the Websites and the Licensed Application, including
              protecting against and responding to fraud, illegal activity (such as incidents
              of hacking or misuse of the Websites or Licensed Application), and claims and
              other liabilities, including by enforcing our End User License Terms;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>evaluating or conducting a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or all of our
              assets, including as part of bankruptcy, liquidation, or similar proceeding;
              and
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:21.1pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>for any other purpose that you may authorize or direct at the
              time we collect your information.
            </p>

            <h2 style='margin-left:-.25pt'>How We Share Your Information</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:18.5pt;
margin-left:.5pt'>
              To accomplish the purposes set out above, we may share your
              information with third parties that may include:
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>the Consana Health customer(s) with whom you are associated or
              for whom you provide services;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in;line-height:107%'>
              <span style='line-height:107%;font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </span>our
              affiliates and business partners;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>our contractors, service providers, and other third parties we
              use to support our organization, which may include support for any of the uses
              of your information described above;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>other third parties as necessary to (a) protect or defend our
              interests and the legal rights or property; (b) protect the rights, interest
              and safety and security of the Websites and Licensed Application, our
              organization, or members of the public; (c) protect against fraud, and (d)
              investigate and prosecute users who engage in behavior that is illegal or
              harmful to others or to others’ property;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>to comply with any court order, law, or legal process, including
              to respond to any government or other regulatory request;
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>a buyer or other successor or organization in the event of an
              actual or potential merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of our assets, including
              as part of bankruptcy, liquidation, or similar proceeding (for information that
              you have provided in connection with your registration for an account to use
              the Licensed Application prior to the Effective Date of this Privacy Policy
              only, we will provide 30 days’ notice of any such transfer and give you an
              opportunity to terminate your account or remove information in your account,
              and if you do not terminate your account or remove information in your account
              during this time period, the information received may be transferred to the
              third party involved in the sale, acquisition, merger, or change-of-ownership
              action); and
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:21.1pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>with such other parties, or for such other purposes, as you may
              authorize or direct when we collect the information.
            </p>

            <h2 style='margin-left:-.25pt'>
              How We Use Cookies and Other Tracking
              Technologies
            </h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:17.8pt;
margin-left:.5pt'>
              We collect certain information about your use of the Licensed
              Application through tracking technologies such as cookies and web beacons. A
              “cookie” is a unique numeric code that is transferred to your computer to track
              your interests and preferences and to recognize you as a return visitor. A “Web
              beacon” is a transparent file placed on a Web site that enables the monitoring
              of things such as user activity and site traffic.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:3.45pt;
margin-left:.5pt;line-height:107%'>
              We and third-parties with whom we work may
              use cookies and web beacons on the
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:3.45pt;
margin-left:.5pt;line-height:107%'>
              Websites and the Licensed Application enable
              the functionality of the Websites and the
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:17.8pt;
margin-left:.5pt'>
              Licensed Application, to improve user experience, to help
              remember your preferences and allow us to bring you the content and features
              that are likely to be of interest to you; to serve you Consana Health-related
              content, including our advertisements, on our Websites and elsewhere on the
              internet, for remarketing and ad personalization; and to analyze the use of the
              Websites and the Licensed Application and to improve the Websites’ and the
              Licensed Application’s functionality. These third parties may collect
              information about your online activities over time and across third-party
              websites or online services.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:.5pt'>
              One of the ways we do this is through the use of Google
              Analytics. For more information about how Google Analytics uses and processes
              data, please visit <a href="https://www.google.com/policies/privacy/partners/">
                <span style='color:#1155CC'>https://www.google.com/policies/privacy/partners/</span>
              </a>.
              For information about interest-based advertising, and to opt out of this type
              of advertising by third parties that participate in self-regulatory programs,
              please visit the Network Advertising Initiative (NAI) opt out tool (<a href="http://www.networkadvertising.org/choices/"><span style='color:#1155CC'>http://www.networkadvertising.org/choices/</span></a>)
              and/or the Digital
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:21.05pt;
margin-left:.5pt'>
              Advertising Alliance (DAA) Self-Regulatory Program for Online
              Behavioral Advertising <a href="https://youradchoices.com/">
                <span style='color:black;text-decoration:none'>(</span>
              </a><a href="https://youradchoices.com/"><span style='color:#1155CC'>https://youradchoices.com/</span></a>).
              Please note that any opt-out choice you exercise through these programs will
              apply to interest-based advertising by the third parties you select but will
              still allow the collection of data for other purposes, including research,
              analytics, and internal operations. You may continue to receive advertising,
              but that advertising may be less relevant to your interests.
            </p>

            <h2 style='margin-left:-.25pt'>Your Choices</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:17.8pt;
margin-left:.5pt'>
              We provide you with choices regarding your information. For
              example, you can choose not to give us the personal information we request, as
              described in the “Information you choose to provide” section of this Privacy
              Policy. However, in some cases, if you decide not to provide the information we
              request, we will not be able to provide the service or information you
              requested.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:19.15pt;
margin-left:.5pt'>Other examples of your choices include:</p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>If you have registered for an account to use the Licensed
              Application, you may request to access, modify, or update information that you
              provide to us, or request to delete information that is not necessary to
              administer your account by contacting Consana Health at <u>
                <span style='color:#1155CC'>support@consanahealth.com</span>
              </u>.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>You may opt-out of receiving our commercial email messages at any
              time by using the opt-out mechanism provided in the message or in the Licensed
              Application.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>You may change your browser settings or take other steps to
              block, manage, or delete cookies. Our Services do not currently respond to
              browser “do not track” signals, so you will need to use your browser settings
              to effectively manage cookies. In some cases, blocking or disabling cookies may
              cause the Services not to work as intended and some features may not be
              available.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:20.95pt;
margin-left:35.25pt;text-indent:-.25in'>
              <span style='line-height:136%;
font-family:"Arial",sans-serif'>
                &#9679;<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>If you would like to stop using your account, you can terminate
              it by contacting Consana Health at <u><span style='color:#1155CC'>support@consanahealth.com</span></u>.
            </p>

            <h2 style='margin-left:-.25pt'>Links to Other Websites and Services</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:21.1pt;
margin-left:.5pt'>
              The Services may contain links to other third-party websites
              and services. These websites and services are not subject to this Privacy
              Policy. They are not controlled by Consana Health and Consana Health is not
              responsible for their content or their privacy policies, which may differ from
              ours. We have not reviewed and make no representations about the accuracy of
              information on third-party websites, or about their information handling
              practices. The visits you make to these websites are at your own risk, and we
              encourage you to review their privacy policies.
            </p>

            <h2 style='margin-left:-.25pt'>Children</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:21.1pt;
margin-left:.5pt'>
              Our Services are not intended for children under the age of
              13, and we do not knowingly collect personal information from children under
              the age of 13. If you are a parent or guardian and believe your child has
              provided us with personal information without your consent, please contact us
              by using the information in the “How to Contact Us” section below.
            </p>

            <h2 style='margin-left:-.25pt'>How to Contact Us</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:.5pt'>
              If you have any questions or comments about this Privacy
              Policy, if you need to report a
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:.5pt'>
              problem, or if you would like to exercise one of your rights
              under this policy, please contact us by email at compliance@consanahealth.com.
              Please include your name, contact information, and the nature of your request
              so that we can respond appropriately and promptly to your communication.
            </p>

            <h2 style='margin-left:-.25pt'>Changes to this Privacy Policy</h2>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:17.8pt;
margin-left:.5pt'>
              From time to time, Consana Health may make changes to this
              Privacy Policy. These changes will be posted at Consana Health.com, and we will
              update the Effective Date at the top of this Privacy Policy.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:17.8pt;
margin-left:.5pt'>
              If you have set up an account to use the Licensed
              Application, you will also be notified through your mobile device, within the
              Licensed Application, and/or the email address you provide to Consana Health.
            </p>

            <p class=MsoNormal style='margin-top:0in;margin-right:.25pt;margin-bottom:.2pt;
margin-left:.5pt'>
              If, after we revise this Privacy Policy, you continue to use Consana
              Health's Websites or the Licensed Application and services, you agree to be
              bound by the terms and conditions of the revised Privacy Policy, or any other
              applicable agreements and policies.
            </p>

          </div>

        </div>
      </template>
      <template v-if="showPrivacy">
        <div id="privacy" class="p-5">

          <div class=WordSection1>

            <h1>Notice of Privacy Practices</h1>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  This notice, created as of January 1, 2024, describes how medical information about you may
                  be used and disclosed and how you can access this information.  Please review
                  it carefully.  If you have any questions, please contact the PRIVACY OFFICE at compliance@consanahealth.com or call 833-444-8840.
                </span>
              </b>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                Consana Health
                Services Comprehensive Medication Management comprises the Virtual Pharmacy (the
                “<b>Clinic</b>”) and OpalCare. Through OpalCare, the Clinic provides its
                pharmacists a comprehensive view of a patient’s medication history in
                conjunction with the associated clinical background such as allergies,
                diagnoses, problem lists, lab results, vital signs, plans of care, procedures,
                etc. in order to provide a complete picture of the patient
              </span><span style='font-family:"Arial",sans-serif;color:red'>. </span><span style='font-family:"Arial",sans-serif'>
                OpalCare helps clinicians reduce
                medication errors by creating a reliable picture of a patient’s medication
                usage and identifying potential problems due to duplication of prescribed
                drugs, hazardous drug combinations, failure to take medications and excessive
                use of opioids; the goal is to enable/ensure optimized medication regimens that
                safely incorporate all prescribed ambulatory, acute, and post-acute medications
                across all of a patient’s prescribing doctors. The privacy practices described
                in this notice will be followed by:
              </span>
            </p>

            <p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'>
              <span style='font-family:Symbol'>
                ·<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><span style='font-family:"Arial",sans-serif'>
                Any health
                pharmacist who views your prescription history, organizes it in order to
                provide a complete picture of that history and makes it available to help
                ensure medication safety and adherence after the care encounter
              </span>
            </p>

            <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'>
              <span style='font-family:Symbol'>
                ·<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><span style='font-family:"Arial",sans-serif'>
                All employees,
                medical staff, trainees, students or volunteers involved with Consana Health
                Services records;
              </span>
            </p>

            <p class=MsoListParagraphCxSpLast style='text-indent:-.25in'>
              <span style='font-family:Symbol'>
                ·<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><span style='font-family:"Arial",sans-serif'>
                Any business
                associates of Consana Health Services
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                Consana Health
                Services is committed to protecting your health information. This notice
                applies to all of the records of your care generated by the Clinic and OpalCare
                only.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                By law Consana
                Health Services is required to:
              </span>
            </p>

            <p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'>
              <span style='font-family:Symbol'>
                ·<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><span style='font-family:"Arial",sans-serif'>
                keep your protected
                health information (PHI) private;
              </span>
            </p>

            <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'>
              <span style='font-family:Symbol'>
                ·<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><span style='font-family:"Arial",sans-serif'>
                give you this notice
                of our legal duties and privacy practices with respect to your PHI; and
              </span>
            </p>

            <p class=MsoListParagraphCxSpLast style='text-indent:-.25in'>
              <span style='font-family:Symbol'>
                ·<span style='font:7.0pt "Times New Roman"'>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span><span style='font-family:"Arial",sans-serif'>
                follow the terms of
                the notice that is currently in effect.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                Special privacy
                protections apply to HIV-related information, alcohol and substance abuse
                treatment information, mental health information, and genetic information.
                Specific authorizations will always be obtained prior to disclosing this type
                of information.
              </span>
            </p>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  HOW CONSANA
                  HEALTH SERVICES MAY USE AND DISCLOSE
                </span>
              </b><span style='font-family:"Arial",sans-serif'>
                <b>YOUR PHI: </b>Consana Health Services may disclose your PHI without your
                prior authorization for treatment purposes, to obtain payment for treatment,
                and to support healthcare operations.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                For purposes of
                healthcare operations, PHI may be disclosed so that Consana Health Services can
                engage in quality improvement or other activities related to the operation of Consana
                Health Services.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                We may use your
                PHI to provide you with a medication assessment or other services. If we
                analyze your information and reach any conclusion pertinent to your care we may
                share information with your physician. We may also make your PHI available to
                providers you may see by making it accessible through a Health Information
                Exchange (HIE), an electronic network that makes it possible to share
                information electronically, but we will not let anyone access it through the
                HIE without your consent except in an emergency (unless you direct us
                otherwise). This means that if your private physician uses an HIE that Consana
                Health Services is part of, s/he will be able to access your PHI relating to
                any Consana Health Services medication assessment or reconciliation.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                For purposes of
                billing and payment we may share information about you with an insurance
                company or third party.
              </span>
            </p>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  Other
                  examples of such disclosures
                </span>
              </b><span style='font-family:"Arial",sans-serif'>
                include
                telling you about, or recommending possible medication options, alternatives,
                or health related benefits or services that may be of interest to you. If we
                are paid to send you treatment information, we will tell you that and give you
                the right not to receive these communications.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                Consana Health
                Services may use or disclose your PHI without prior authorization, subject to
                certain requirements, for the following: to family and friends involved in your
                care unless you object; for public health purposes, domestic violence and/or
                abuse or neglect reporting, health inspections or audits, emergencies, averting
                a serious threat to the health or safety of a person or the public, issues
                relating to national security, and specialized government functions (e.g.
                disclosure of PHI of individuals who are members of the Armed Forces as
                required by Military Command authorities). Consana Health Services may also
                disclose PHI when required by law, for law enforcement purposes, to
                correctional institutions, for law enforcement custodial matters, or in
                response to valid judicial or administrative orders or other lawful process. In
                most cases we will ask for your written authorization before using your PHI or
                sharing it with others in order to conduct research. However, we may use your
                PHI without your written authorization if, as part of a special process
                required by law that reviews protection for persons involved in research, a
                determination is made that the particular research project poses a minimal risk
                to your privacy.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                In any other
                situation not covered by this NOPP Consana Health Services will ask for your
                written authorization before using or disclosing your PHI. If you choose to
                authorize disclosure, you are permitted to revoke that authorization, except to
                the extent that we have already relied on it, by notifying us in writing of your
                decision addressed to the Privacy Office, Consana Health Services, 1010 East
                Arapaho Road, Suite 102, Richardson, TX 75081.
              </span>
            </p>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  RIGHT TO
                  ACCESS AND/OR AMEND YOUR RECORDS:
                </span>
              </b>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                You have a
                right to inspect and obtain a copy in either electronic or paper form of any
                PHI that may be used to make decisions about you and your treatment for as long
                as we maintain this information in our records. We will produce the records in
                the specific electric format that you request if it is feasible to do so. If
                your request is denied, you may submit a written request for a review of that
                decision.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                If you feel
                information in your record is incorrect or that the information is incomplete,
                you have the right to request that we amend the record; this request must be in
                writing and provide your reason for requesting the amendment. Consana Health
                Services may deny your request to amend if we do not maintain the information
                or if we determine that the record is accurate. You may submit a written
                statement detailing your disagreement with this decision. We will inform you if
                the amendment is accepted.
              </span>
            </p>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  RIGHT TO AN
                  ACCOUNTING:
                </span>
              </b>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                You have the
                right to request a listing of any disclosures of your PHI that Consana Health
                Services has made, except for uses and disclosures related to treatment,
                payment, or healthcare operations, circumstances in which you have specifically
                authorized such disclosure, disclosures made specifically to you and certain
                other exceptions. This listing may not include more than the last six years.
                Such requests should be submitted in writing to the Privacy Office, Consana
                Health Services, 1010 East Arapaho Road, Suite 102, Richardson, TX 75081.
              </span>
            </p>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  RIGHT TO
                  RESTRICTIONS
                </span>
              </b><span style='font-family:"Arial",sans-serif'>:</span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                You have the
                right to request, in writing, that Consana Health Services not disclose your
                PHI for treatment, payment, or healthcare operations or to persons involved in
                your care except when specifically authorized by you, when required by law, or
                in an emergency. Consana Health Services will review your request and attempt
                to accommodate it when possible, but Consana Health Services is not legally
                required to accept it. All written requests or appeals should be submitted to
                the Privacy Officer at the address above. You have the right to direct us not
                to share specific PHI with your insurance company if you plan to pay for a
                service personally without submitting a claim to your insurer. It is your
                responsibility to inform other providers who may receive copies of your record
                that they may not share this PHI with your insurance company.
              </span>
            </p>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  REQUESTS FOR
                  CONFIDENTIAL COMMUNICATIONS:
                </span>
              </b>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                You have a
                right to request that your PHI be communicated to you in a confidential manner.
                Such requests must be made in writing.
              </span>
            </p>

            <p class=MsoNormal><b><span style='font-family:"Arial",sans-serif'>NOTIFICATION:</span></b></p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                You will be
                notified within 60 days if your PHI has been disclosed to or accessed by a
                person who was not authorized to receive the information.
              </span>
            </p>

            <p class=MsoNormal>
              <b>
                <span style='font-family:"Arial",sans-serif'>
                  CHANGES TO
                  THIS NOPP:
                </span>
              </b>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                We may change
                our policies at any time. When significant changes occur we will change our
                NOPP and post the new notice. You may receive a copy of the current NOPP at
                anytime. Copies of the NOPP will be available at
              </span><u>
                <span style='font-family:"Arial",sans-serif;color:blue'>
                  <a href="http://www.consanahealth.com">www.consanahealth.com</a>
                </span>
              </u>
            </p>

            <p class=MsoNormal><b><span style='font-family:"Arial",sans-serif'>COMPLAINTS:</span></b></p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                If you believe
                that your privacy rights have been violated, or you disagree with a decision Consana
                Health Services made about access to your records, you may contact the Privacy
                Office at compliance@consanahealth.com or call 833-444-8840.
              </span>
            </p>

            <p class=MsoNormal>
              <span style='font-family:"Arial",sans-serif'>
                If you are not
                satisfied with our response, you may send a written complaint to the U.S.
                Department of Health and Human Services Office of Civil Rights at <a href="http://www.hhs.gov/ocr/hipaa">www.hhs.gov/ocr/hipaa</a>. Under no circumstances will you be penalized or
                subject to retaliation for filing a complaint.
              </span>
            </p>

          </div>

        </div>
      </template>
      <index-articles :show-signup="true" :hide-articles="true"></index-articles>
    </div>
	</div>
	<page-footer />
</template>

<script>
export default {
	data() {
		return {
			title: "Notice of Privacy Practices",
      showPrivacy: false,
			showWebPrivacy: false
		};
	},
	methods:{
    handleShowPrivacy() {
      this.showWebPrivacy = false
			this.showPrivacy = true
			this.$router.push({to:'/privacy-policy', hash:'#privacy'})
    },
		handleHidePrivacy() {
      this.showPrivacy = false
      this.$router.push({ to: '/privacy-policy' })
    },
    handleShowWebPrivacy() {
      this.showPrivacy = false
      this.showWebPrivacy = true
      this.$router.push({ to: '/privacy-policy', hash: '#web-privacy' })
    },
    handleHideWebPrivacy() {
      this.showWebPrivacy = false
      this.$router.push({ to: '/privacy-policy' })
    }
	}
};
</script>

<style scoped>
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Century Gothic";
	panose-1:2 11 5 2 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:5.05pt;
	margin-bottom:.2pt;
	margin-left:7.25pt;
	text-indent:-.5pt;
	line-height:136%;
	font-size:11.0pt;
	font-family:"Century Gothic",sans-serif;
	color:black;}
h1
	{mso-style-link:"Heading 1 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:2.2pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:20.0pt;
	font-family:"Century Gothic",sans-serif;
	color:black;}
h2
	{mso-style-link:"Heading 2 Char";
	margin-top:0in;
	margin-right:0in;
	margin-bottom:6.7pt;
	margin-left:.5pt;
	text-indent:-.5pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:14.0pt;
	font-family:"Century Gothic",sans-serif;
	color:black;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char";
	mso-style-link:"Heading 2";
	font-family:"Century Gothic",sans-serif;
	color:black;
	font-weight:bold;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char";
	mso-style-link:"Heading 1";
	font-family:"Century Gothic",sans-serif;
	color:black;
	font-weight:bold;}
.MsoChpDefault
	{font-size:12.0pt;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:115%;}
@page WordSection1
	{size:8.5in 11.0in;
	margin:60.65pt 72.2pt 75.45pt 1.0in;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0in;}
ul
	{margin-bottom:0in;}
</style>