<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <page-banner title="Get in Touch"
                 text="Consana Health is here to provide you with more information,
			answer any questions you may have, and create an effective solution that fills in the
			blanks for you, your practice, or your healthcare organization." />
    <div class="page-wrapper">
      <!-- Contact Us -->
      <section class="contact-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-12">
              <div class="section-inner-header contact-inner-header">
                <h6>Get in touch</h6>
                <h2>Have Any Question?</h2>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-phone"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Phone Number</h4>
                    <p>(833) 444-8840</p>
                  </div>
                </div>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-mail"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Email Address</h4>
                    <p>info@consanahealth.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 d-flex">
              <div class="card contact-form-card w-100">
                <div class="card-body">
                  <div v-if="responseMessage" class="col-md-12 bg-success white-font">{{responseMessage}}</div>
                  <div v-else-if="errorMessage" class="col-md-12 bg-danger white-font">{{errorMessage}}</div>
                  <form action="javascript:;">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Name</label>
                          <input type="text"
                                 v-model="viewModel.name"
                                 class="form-control"
                                 placeholder="Enter Your Name" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Email Address</label>
                          <input type="text"
                                 v-model="viewModel.email"
                                 class="form-control"
                                 placeholder="Enter Email Address" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Phone Number</label>
                          <input type="text"
                                 v-model="viewModel.phone"
                                 class="form-control"
                                 placeholder="Enter Phone Number" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="mb-2">Subject</label>
                          <input type="text"
                                 v-model="viewModel.subject"
                                 class="form-control"
                                 placeholder="Enter Subject" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="mb-2">Message</label>
                          <textarea v-model="viewModel.message"
                                    class="form-control"
                                    placeholder="Enter your comments"></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <vue-recaptcha sitekey="6LdJr8UUAAAAAB3SH2eErFntLTL3zNv1FPKzHZQ5"
                                       @verify="isVerified = true"
                                       @error="isVerified = false"></vue-recaptcha>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group-btn mb-0">
                          <b-button v-if="!successMessage && !errorMessage"
                                    :disabled="!isVerified"
                                    type="submit"
                                    class="btn btn-primary prime-btn"
                                    @click="handleSendMessage">Send Message</b-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Contact Us -->
    </div>
    <page-footer />
  </div>
</template>

<script>
  import { VueRecaptcha } from 'vue-recaptcha';
  import apiClient from '../../api/apiClient'

  export default {
    components: { VueRecaptcha },
    data() {
      return {
        title: "Contact Us",
        text: "Home",
        text1: "Contact Us",
        isVerified: false,
        responseMessage: null,
        errorMessage: null,
        viewModel: {
          name: null,
          email: null,
          phone: null,
          subject: null,
          message: null
        }
      };
    },
    methods: {
      async handleSendMessage() {
        try {
          var response = await apiClient.post('contact', this.viewModel)
          if (response.status !== 200) {
            this.errorMessage = `Error sending message: ${response.statusText}`
          }
        } catch (error) {
          console.log(error)
          this.errorMessage = "Error sending message."
          throw error
        }
        this.responseMessage = "Message successfully sent."
      }
    }
  };
</script>
