<template>

	<!-- Way Section -->
	<section class="way-section">
		<div class="container">
			<div class="way-bg">
				<div class="way-shapes-img">
					<div class="way-shapes-left">
						<img src="@/assets/img/shape-06.png" alt="Image" />
					</div>
					<div class="way-shapes-right">
						<img src="@/assets/img/shape-07.png" alt="Image" />
					</div>
				</div>
				<div class="row align-items-end">
					<div class="col-lg-7 col-md-12">
						<div class="section-inner-header way-inner-header mb-0">
							<h2 class="text-white">
								Effective Medication Management Matters!
							</h2>
							<p>
								Unlock better patient outcomes with effective medication management. By reducing complications, minimizing hospitalizations, and enhancing overall health, you can transform patient care. Partner with Consana Health to implement a robust medication management system today.
							</p>
							<router-link to="/contact-us" class="btn btn-primary">Contact Us</router-link>
						</div>
					</div>
					<div class="col-lg-5 col-md-12">
						<div class="way-img">
							<img src="@/assets/img/way-img.png"
									 class="img-fluid"
									 alt="Image" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Way Choose Us -->
</template>

<script>
	import AOS from "aos";
	import "aos/dist/aos.css";
	export default {
		mounted() {
			this.$nextTick(() => {
				AOS.init();
			});
		},
	};
</script>
