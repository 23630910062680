import { createApp } from 'vue'
import { router } from './router';
import App from './App.vue'
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueSelect from 'vue3-select2-component'
import VCalendar from 'v-calendar';
import VueFeather from 'vue-feather';
import 'vue3-datepicker/dist/vue3-datepicker.css';
import VueApexCharts from "vue3-apexcharts";
import Vue3Autocounter from 'vue3-autocounter';
import VueTelInput from 'vue3-tel-input';
// plugins
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue3-tel-input/dist/vue3-tel-input.css';

/***********************************************************************/
                     /* Frontend */
/***********************************************************************/

//Breadcrumb
import BlogSidebar from '@/views/layouts/blogsidebar.vue'
import Mainmenu from '@/components/mainmenu.vue'
import Mainnav from '@/components/mainnav.vue'
import Profilewidget from '@/components/profilewidget.vue'
import PageBanner from '@/components/pagebanner.vue'


//pages
import Header from '@/views/layouts/header.vue'
import Headerinfo from '@/views/layouts/header-info.vue'
import Scrolltotop from '@/views/layouts/scrolltotop.vue'
import ProfileSidebar from '@/views/layouts/profilesidebar.vue'
import Footer from '@/views/layouts/footer.vue'

import IndexBanner from '@/views/pages/home/indexbanner.vue'
import IndexDoctor from '@/views/pages/home/indexdoctor.vue'
import IndexWork from '@/views/pages/home/indexwork.vue'
import IndexWorkHow from '@/views/pages/home/indexworkhow.vue'
import IndexApp from '@/views/pages/home/indexapp.vue'
import IndexAsk from '@/views/pages/home/indexask.vue'
import IndexFaq from '@/views/pages/home/indexfaq.vue'
import IndexArticles from '@/views/pages/home/indexarticles.vue'

import CompanyInvestors from '@/views/pages/investors.vue'

import IndexPartners from '@/views/pages/partner/index-partners.vue'
import PayorPartners from '@/views/pages/partner/payor-partners.vue'
import HealthSystemPartners from '@/views/pages/partner/health-system-partners.vue'

import BlogGrid from '@/views/pages/blog/blog-grid.vue'
import BlogdetailComment from '@/views/pages/blog/blog-details-comment.vue'

import HealthSystemTestimonial from '@/views/pages/testimonial/health-system-testimonial.vue'
import PayorTestimonial from '@/views/pages/testimonial/health-system-testimonial.vue'
import IndexTestimonial from '@/views/pages/testimonial/indextestimonial.vue'

import PayorBenefits from '@/views/pages/payors/payors-benefits.vue'
import PayorStatistics from '@/views/pages/payors/payor-statistics.vue'
import CtaPayors from '@/views/pages/calltoaction/cta-payor.vue'
import CtaPayorsTwo from '@/views/pages/calltoaction/cta-payor-2.vue'

import HealthSystemStatistics from '@/views/pages/health-system/health-systems-statistics.vue'
import HealthSystemLearnMore from '@/views/pages/health-system/health-systems-learn-more.vue'



/***********************************************************************/
                      /*  Pharmacy */
/***********************************************************************/
const app = createApp(App);

// Breadcrumb
app.component('blog-sidebar',BlogSidebar)
app.component('main-menu',Mainmenu)
app.component('main-nav',Mainnav)
app.component('profile-widget',Profilewidget)


//pages
app.component('layout-header', Header)
app.component('header-info',Headerinfo)
app.component('scroll-to-top',Scrolltotop)
app.component('page-footer',Footer)
app.component('index-banner',IndexBanner)
app.component('index-doctor',IndexDoctor)
app.component('index-work', IndexWork)
app.component('index-work-how', IndexWorkHow)
app.component('index-app', IndexApp)
app.component('index-ask', IndexAsk)
app.component('index-articles', IndexArticles)
app.component('index-faq', IndexFaq)
app.component('blog-grid',BlogGrid)

app.component('company-investors',CompanyInvestors)

app.component('index-partners',IndexPartners)
app.component('index-testimonial',IndexTestimonial)

app.component('payor-partners',PayorPartners)
app.component('payor-testimonial',PayorTestimonial)
app.component('payor-benefits',PayorBenefits)
app.component('payor-statistics',PayorStatistics)
app.component('cta-payors',CtaPayors)
app.component('cta-payors-two',CtaPayorsTwo)

app.component('health-system-partners',HealthSystemPartners)
app.component('health-system-testimonial',HealthSystemTestimonial)
app.component('health-system-statistics',HealthSystemStatistics)
app.component('health-system-learn-more',HealthSystemLearnMore)


app.component('page-banner',PageBanner)

app.component('blog-details-comment',BlogdetailComment)

//Patient pages
app.component('profile-sidebar',ProfileSidebar)

app.component('vue3-autocounter', Vue3Autocounter)
app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather)
app.use(VueApexCharts)
.use(VueTelInput)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
app.use(VCalendar, {})
app.use(router).mount('#app');