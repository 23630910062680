import {createRouter, createWebHistory} from 'vue-router';

import Index from '@/views/pages/home/index.vue'
import AboutUs from '@/views/pages/about-us.vue'
import ForPayors from '@/views/pages/payors/for-payors.vue'
import ForHealthSystems from '@/views/pages/health-system/for-health-systems.vue'
import ForPharmacists from '@/views/pages/pharmacist/for-pharmacists.vue'
import MTM from '@/views/pages/mtm.vue'
import CMM from '@/views/pages/cmm.vue'
import BlogList from '@/views/pages/blog/blog-list.vue'
import BlogGrid from '@/views/pages/blog/blog-grid.vue'
import BlogDetails from '@/views/pages/blog/blog-details.vue'
import ContactUs from '@/views/pages/contact-us.vue'




import LoginEmail from '@/views/pages/authentication/login-email.vue'
import LoginPage from '@/views/pages/authentication/login.vue'
import Mobileotp from '@/views/pages/authentication/mobile-otp.vue'
import LoginPhone from '@/views/pages/authentication/login-phone.vue'
import ForgotPassword from '@/views/pages/authentication/forgot-password.vue'
import ForgotPasswordTwo from '@/views/pages/authentication/forgot-password2.vue'
import LoginEmailOtp from '@/views/pages/authentication/login-email-otp.vue'
import EmailOtp from '@/views/pages/authentication/email-otp.vue'
import LoginPhoneOtp from '@/views/pages/authentication/login-phone-otp.vue'

import BlankPage from '@/views/pages/common/blank-page.vue'
import Privacypolicy from '@/views/pages/common/privacy-policy.vue'
import Maintenance from '@/views/pages/common/maintenance.vue'
import ComingSoon from '@/views/pages/common/coming-soon.vue'
import Error404 from '@/views/pages/common/error-404.vue'
import Error500 from '@/views/pages/common/error-500.vue'


const routes = [
/**************** Frontend  *************/
    {
        path: '/',
        name: 'index',
        component: Index,
  },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs
    },
    {
        path: '/contact-us',
        name: 'contact-us',
        component: ContactUs
    },
    {
        path: '/for-payors',
        name: 'for-payors',
        component: ForPayors
    },
    {
        path: '/for-health-systems',
        name: 'for-health-systems',
        component: ForHealthSystems
    },
    {
        path: '/for-pharmacists',
        name: 'for-pharmacists',
        component: ForPharmacists
    },
    {
        path: '/our-culture',
        name: 'our-culture',
        component: ForPharmacists
    },
    {
        path: '/medication-therapy-management',
        name: 'medication-therapy-management',
        component: MTM
    },
    {
        path: '/comprehensive-medication-management',
        name: 'comprehensive-medication-management',
        component: CMM
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: Error404
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: Error500
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: Privacypolicy
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: Maintenance
    },
    {
        path: '/coming-soon',
        name: 'coming-soon',
        component: ComingSoon
    },
    {
        path: '/blog-list',
        name: 'blog-list',
        component: BlogList
    },
    {
        path: '/blog-grid',
        name: 'blog-grid',
        component: BlogGrid
    },
    {
        path: '/blog-details',
        name: 'blog-details',
        component: BlogDetails
    },

/**************** / Frontend  *************/














    {
        path: '/mobile-otp',
        name: 'mobile-otp',
        component: Mobileotp
    },
    {
        path: '/login-email',
        name: 'login-email',
        component: LoginEmail
    },
    {
        path: '/login-phone',
        name: 'login-phone',
        component: LoginPhone
    },
    {
        path: '/forgot-password2',
        name: 'forgot-password2',
        component: ForgotPasswordTwo
    },
    {
        path: '/login-email-otp',
        name: 'login-email-otp',
        component: LoginEmailOtp
    },
    {
        path: '/email-otp',
        name: 'email-otp',
        component: EmailOtp
    },
    {
        path: '/login-phone-otp',
        name: 'login-phone-otp',
        component: LoginPhoneOtp
    },
    {
        path: '/blank-page',
        name: 'blank-page',
        component: BlankPage
    },
]


export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
}); 

router.beforeEach((to, from, next) => {
  console.log(to)
// Scroll to the top of the page
window.scrollTo({ top: 0, behavior: 'smooth' });

// Continue with the navigation
next();
});