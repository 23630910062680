<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title" :text="text" />

		<div class="page-wrapper">
			<!-- Page Content -->
			<section class="content">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-8 section-header-one">
							<h2 class="section-title">Our Core Values</h2>
						</div>
					</div>
				</div>
			</section>
			<section class="content grey-with-background">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-8 section-header-one">
							<h2 class="section-title">Employee Spotlights</h2>
						</div>
					</div>
				</div>
			</section>
			<section class="content">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-8 section-header-one">
							<h2 class="section-title">Why We&rsquo;re Here</h2>
						</div>
					</div>
				</div>
			</section>
			<section class="content grey-with-background">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-8 section-header-one">
							<h2 class="section-title">Benefits, Perks & More</h2>
						</div>
					</div>
				</div>
			</section>
			<section class="content">
				<div class="container">
					<div class="row justify-content-center">
						<div class="col-lg-8">
							<img
								src="@/assets/img/about-img2.jpg"
								class="img-fluid"
								alt="Image"
							/>
						</div>
					</div>
				</div>
			</section>
			<!-- /Page Content -->
		</div>
	</div>
	<page-footer />
</template>

<script>
export default {
	data() {
		return {
			title: "Consana is <span>Changing Healthcare</span>",
			text: "Working with us means working toward better, safer, more effective medication use for everyone. We have the expertise, resources, and technology to make a difference in the lives of patients and physicians. If you have similar goals, let&rsquo;s work together!",
		};
	},
};
</script>
