<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title" :text="text" />
		<div class="page-wrapper">
			<!-- Page Content -->
			<div class="content">
				<div class="container">
					<div class="row">
						<div class="col-lg-8 col-md-12"></div>
						<!-- Blog Sidebar -->
						<blog-sidebar></blog-sidebar>
						<!-- /Blog Sidebar -->
					</div>
				</div>
			</div>
			<!-- /Page Content -->
		</div>
	</div>
	<page-footer />
</template>

<script>
import Blogs from "@/assets/json/blogs.json";
export default {
	data() {
		return {
			Blogs: Blogs,
			title: "Blog List",
			text: "Home",
		};
	},
};
</script>
