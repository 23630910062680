<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title" :text="text" >
						<router-link to="/contact-us" class="btn"
							>Let&rsquo;s Talk</router-link
						></page-banner>
		<div class="page-wrapper">
			<!-- Page Content -->
			<payor-benefits/>
			<payor-statistics class="grey-with-background"/>
			<index-testimonial class="grey-with-background" />
			<cta-payors class="pt-5"/>
			<!-- /Page Content -->
		</div>
	</div>
	<page-footer />
</template>

<script>
export default {
	data() {
		return {
			title:
				"Elevate Quality of Care and <span>Lower Overall Cost of Care</span> with a Concierge Pharmacist Experience.",
			text: "Experience the future: Consana brings you a trusted, white-glove clinical pharmacist service for members, providing timely, high-quality medication reconciliation and comprehensive medication management for high-risk situations. <strong>Your members&rsquo; well-being is in expert hands</strong>.",
		};
	},
};
</script>
