<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title" :text="text" >
						<router-link to="/booking" class="btn"
							>Let&rsquo;s Talk</router-link
						></page-banner>
		<div class="page-wrapper">
			<!-- Page Content -->
			<section class="`health-system-section ${cssClass}`">
				<div class="container">
					<div class="row">
						<div class="col-lg-4 col-md-12 health-system-img-info aos" data-aos="fade-up">
							<div class="health-system-img">
								<img
									src="@/assets/img/Pain-Point-1.jpg"
									class="img-fluid"
									alt="Image"
								/>
							</div>
						</div>
						<div class="col-lg-8 col-md-12 health-system-details">
							<div class="section-header-one aos" data-aos="fade-up">
								<h2 class="section-title">
									Get the Full Medication Story
								</h2>
								<ul>
									<li><span>Stay fully informed</span> of patients&rsquo; medication picture outside your EMR, including scripts paid for with cash, coupons, and workers&rsquo; comp</li>
									<li><span>Optimize regimens with smart alerts</span> that inform you about possible beneficial medication changes</li>
									<li><span>Connect the dots with your ambulatory care network</span> with Consana clinical pharmacists and best in class transitions of care services</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-8 col-md-12 health-system-details">
							<div class="section-header-one aos" data-aos="fade-up">
								<h2 class="section-title">
									Minimize Financial — and Human — Risk
								</h2>
								<ul>
									<li><span>Reduce unnecessary readmissions</span>, full stop</li>
									<li><span>Increase value based care performance</span> and avoid downstream penalties from the HRRP</li>
									<li><span>Alert providers of dangerous suboptimal medication regimens</span> and errors before it&rsquo;s too late, decrease downstream risk and insurance claims, and of course, save lives</li>
								</ul>
							</div>
						</div>
						<div class="col-lg-4 col-md-12 health-system-img-info aos" data-aos="fade-up">
							<div class="health-system-img">
								<img
									src="@/assets/img/about-img3.jpg"
									class="img-fluid"
									alt="Image"
								/>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-4 col-md-12 health-system-img-info aos" data-aos="fade-up">
							<div class="health-system-img">
								<img
									src="@/assets/img/about-img1.jpg"
									class="img-fluid"
									alt="Image"
								/>
							</div>
						</div>
						<div class="col-lg-8 col-md-12 health-system-details">
							<div class="section-header-one aos" data-aos="fade-up">
								<h2 class="section-title">
									Increase Quality Ratings and More
								</h2>
								<ul>
									<li><span>Be recognized as a quality leader</span> by programs like CMS star rankings and Leapfrog</li>
									<li><span>Close transitions of care gaps</span> between ambulatory care providers and patients safely settled in at home with help from Consana clinical pharmacists</li>
									<li><span>Give patients concierge-level personal attention</span> and the satisfaction of knowing they have the most effective, safest, affordable medication regimen for them</li>
									<li><span>Attract top talent:</span> physicians and advanced practitioners interested in working at organizations that both walk the high-quality walk and support them with the data, tools, and team to do their best work</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<health-system-statistics />
			<health-system-testimonial class="grey-with-background" />
			<health-system-partners />
			<cta-payors-two />
			<indextestimonial class="grey-with-background" />
			<!-- /Page Content -->
		</div>
	</div>
	<page-footer />
</template>

<script>
export default {
	data() {
		return {
			title:
				"Resolve the <span>Medication Management Crisis</span> With a New Level of Patient Care",
			text: "Empower your hospital or health system with Consana, a solution that simplifies medication management through a distinctive blend of cutting-edge software and personalized human interaction. Proven to mitigate risk, decrease readmissions, and elevate quality rankings, Consana provides a comprehensive review of patients&rsquo; medication profiles. Ensure a secure transition home for the most vulnerable, promoting lasting health and happiness through enhanced quality of care. <br/> <br/> Experience the effectiveness firsthand. Share details about your organization, and let us demonstrate how Consana can assist you in achieving the level of patient care your organization has always strived for.",
		};
	},
};
</script>
