<template>
  <!-- Statistics Section -->
  <section class="statistics-section">
    <div class="bg-primary p-5">
      <div class="container">
        <div class="row sr-row text-white">
          <div class="col-md-4 text-center p-2">
            <div class="content-wrapper h-100">
              <div class="col-content rounded p-1 h-100" style="background-color: #00a6ce">
                <h2 class="text-white" style="font-size: 65px; margin-bottom: 0!important;">4<span style="font-size: 18px;"> avg.</span></h2>
                <p>
                  Drug Therapy Problems <br />
                  resolved per visit
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4 sr-col text-center p-2">
            <div class="content-wrapper h-100">
              <div class="col-content rounded p-1 h-100 align-center" style="background-color: #00a6ce">
                <div>
                  <h2 class="text-white" style="font-size: 65px; margin-bottom: 0!important;"><span style="font-size: 18px;">over</span>95<span style="font-size: 40px;"><strong>%</strong></span></h2>
                  <p>
                    Patient and Caregiver
                    Satisfaction Rate
                    <br>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 sr-col text-center p-2">
            <div class="content-wrapper h-100">
              <div class="col-content rounded p-1 h-100 align-center" style="background-color: #00a6ce">
                <div>
                  <h2 class="text-white" style="font-size: 65px; margin-bottom: 0!important;">1.1<span style="font-size: 40px;"><strong>%</strong></span></h2>
                  <p>A1C Reducation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Statistics Section -->
</template>
<script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import IndexApp from "@/assets/json/indexapp.json";
  export default {
    data() {
      return {
        IndexApp: IndexApp,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
  };
</script>
