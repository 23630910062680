<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title" :text="text" />
		<div class="page-wrapper">
			<!-- About Us -->
			<section class="about-section">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-6 col-md-12">
							<div class="about-img-info">
								<div class="row">
									<div class="col-md-6">
										<div class="about-inner-img">
											<div class="about-img">
												<img
													src="@/assets/img/about-img1.jpg"
													class="img-fluid"
													alt="Image"
												/>
											</div>
											<div class="about-img">
												<img
													src="@/assets/img/about-img2.jpg"
													class="img-fluid"
													alt="Image"
												/>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="about-inner-img">
											<div class="about-box">
												<h4>Years of Experience</h4>
											</div>
											<div class="about-img">
												<img
													src="@/assets/img/about-img3.jpg"
													class="img-fluid"
													alt="Image"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-md-12">
							<div class="section-inner-header about-inner-header">
								<h6>About Our Company</h6>
								<h2 class="section-title">
									We Are Always Ensure Best Medical Treatment For Your Health
								</h2>
							</div>
							<div class="about-content">
								<div class="about-content-details">
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua. Ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
										Duis aute irure dolor in reprehenderit in voluptate velit
										esse cillum dolore eu fugiat nulla pariatur.
									</p>
									<p>
										Sed ut perspiciatis unde omnis iste natus sit voluptatem
										accusantium doloremque eaque ipsa quae architecto beatae
										vitae dicta sunt explicabo.
									</p>
								</div>
								<div class="about-contact">
									<div class="about-contact-icon">
										<span
											><img src="@/assets/img/icons/phone-icon.svg" alt="Image"
										/></span>
									</div>
									<div class="about-contact-text">
										<p>Need Emergency?</p>
										<h4>+1 315 369 5943</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /About Us -->

			<!-- Why Choose Us -->
			<section class="why-choose-section">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="section-inner-header text-center">
								<h2 class="section-title">Why Choose Us</h2>
							</div>
						</div>
					</div>
					<div class="row">
						<div
							class="col-lg-3 col-md-6 d-flex"
							v-for="item in AboutusChoose"
							:key="item.id"
						>
							<div class="card why-choose-card w-100">
								<div class="card-body">
									<div class="why-choose-icon">
										<span
											><img
												:src="require(`@/assets/img/icons/${item.Image}`)"
												alt="Image"
										/></span>
									</div>
									<div class="why-choose-content">
										<h4>Qualified Staff of Pharmacists</h4>
										<p>
											Lorem ipsum sit amet consectetur incididunt ut labore et
											exercitation ullamco laboris nisi dolore magna enim veniam
											aliqua.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Why Choose Us -->

			<!-- Way Section -->
			<section class="way-section">
				<div class="container">
					<div class="way-bg">
						<div class="way-shapes-img">
							<div class="way-shapes-left">
								<img src="@/assets/img/shape-06.png" alt="Image" />
							</div>
							<div class="way-shapes-right">
								<img src="@/assets/img/shape-07.png" alt="Image" />
							</div>
						</div>
						<div class="row align-items-end">
							<div class="col-lg-7 col-md-12">
								<div class="section-inner-header way-inner-header mb-0">
									<h2 class="text-white">Make Medications Manageable With Consana</h2>
									<p>
										Envision a world where individuals in need of medications
										can safely and effectively access them. That's our goal at
										Consana — making this vision a reality for a healthier and
										more secure future.
									</p>
									<router-link to="/contact-us" class="btn btn-primary"
										>Contact Us</router-link
									>
								</div>
							</div>
							<div class="col-lg-5 col-md-12">
								<div class="way-img">
									<img
										src="@/assets/img/way-img.png"
										class="img-fluid"
										alt="Image"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Way Choose Us -->

			<indexdoctor />

			<indextestimonial class="grey-with-background" />

			<indexfaq />
		</div>
		<page-footer />
	</div>
</template>

<script>
import AboutusChoose from "@/assets/json/aboutuschoose.json";
import AboutusDoctors from "@/assets/json/aboutusdoctors";
import IndexTestimonial from "@/assets/json/indextestimonial.json";
import "vue3-carousel/dist/carousel.css";

export default {
	data() {
		return {
			AboutusChoose: AboutusChoose,
			AboutusDoctors: AboutusDoctors,
			title: "What Does Consana Do?",
			text: "Empowering health plans, healthcare organizations, and patients alike, we ensure that medications are taken appropriately, effectively, and, above all, safely. <br/> Our patients&rsquo; well-being is our top priority.",
			IndexTestimonial: IndexTestimonial,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
			},
			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				767: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				991: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				1024: {
					itemsToShow: 1,
					snapAlign: "start",
				},
			},
		};
	},
};
</script>
