<template>
	<!-- Work Section -->
	<section class="work-how-section">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="section-header-one text-center aos" data-aos="fade-up">
						<h5>Why it Works</h5>
						<h2 class="section-title">
							Doesn&rsquo;t my doctor already do this?
						</h2>
						<p style="max-width: 707px; display: inline-block">
							While doctors excel in diagnoses and general treatment, managing
							complex medication plans requires a specialized touch. Just as you
							seek a specialist for specific health needs, trust our experts to
							optimize your medication selection, dosing, and monitoring. Your
							well-being deserves tailored attention &ndash; choose a healthcare
							partner dedicated to maximizing the effectiveness of your
							treatment regimen.
						</p>
					</div>
					<div class="row justify-content-center">
						<div class="col-lg-5 col-md-12 px-3 aos" data-aos="fade-up">
							<div class="work-how-info">
								<div class="work-how-content">
									<h5>
										Decreased Risk of Harm Caused by a Medication and
										Hospitalizations
									</h5>
									<p>
										Consider that following a medical appointment or visit to
										the hospital:
									</p>
									<ul class="mt-2" style="list-style-type: none">
										<li>
											<p>
												<span
													style="
														font-size: 24px;
														font-weight: bold;
														color: #00a6ce;
													"
													>53%</span
												>
												of adult patients leave with at least one medication
												error
											</p>
										</li>
										<li>
											<p>
												<span
													style="
														font-weight: bold;
														font-size: 24px;
														color: #00a6ce;
													"
													>27%</span
												>
												of patients experience harm from using a medication
												following a hospital discharge
											</p>
										</li>
										<li>
											<p>
												<span
													style="
														font-weight: bold;
														color: #00a6ce;
														font-size: 24px;
													"
													>20%</span
												>
												of patients are readmitted due to an adverse reaction to
												a medication
											</p>
										</li>
									</ul>
									<p>
										We make sure that any new medications following a medical
										appointment or hospital visit are safe for you to take with
										your current medications. We also take the time to help
										ensure you understand how to properly take your medications
										to keep you healthy and out of the hospital.
									</p>
								</div>
							</div>
						</div>
						<div
							class="col-lg-5 offset-lg-1 col-md-12 px-3 aos"
							data-aos="fade-up"
						>
							<div class="work-how-info">
								<div class="work-how-content">
									<h5>Fewer Medications</h5>
									<p>
										If you see several healthcare providers and specialists,
										like a primary care provider and a cardiologist, you may be
										prescribed medications that are redundant, harmfully
										interact with other medications you may be taking, increase
										safety risks, or do little to help you progress toward to
										your health goals. <br /><br />We review all of your
										medications across providers, so we can remove those
										medications you don't need to take or shouldn't be taking.
										This leads to a simpler medication plan, lower costs, and
										reduced chance of bad reactions and side effects.
									</p>
								</div>
							</div>
						</div>
						<div class="col-lg-5 col-md-12 px-3 aos" data-aos="fade-up">
							<div class="work-how-info">
								<div class="work-how-content">
									<h5>Peace of Mind</h5>
									<p>
										All Consana Health pharmacists have received their PharmD (a Doctor
										of Pharmacy degree), are board certified with an accredited
										residency training, and are trained to work with patients,
										doctors, and caregivers. In other words, they're some of the
										most highly trained and educated pharmacists in the country.
										Our pharmacists perform routine, one-on-one check-ins with
										you and then reach out whenever new medications are
										prescribed or when you meet with a healthcare provider about
										a medical issue. This way, you can rest assured that your
										medications are supporting your health goals.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Work Section -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
