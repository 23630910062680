<template>
	<div class="main-wrapper">
		<layout-header />
		<index-banner />
		<index-work-how />
		<index-work class="grey-with-background" />
		<index-faq />
		<!--indexdoctor /-->
		<!--indexapp /-->
		<index-testimonial class="grey-with-background" />
		<index-ask />
		<!--indexarticles />
		<indexpartners /-->
		<page-footer />
	</div>
</template>
<script>
export default {
	name: "index",
};
</script>
