<template>
	
			<!-- Way Section -->
			<section class="way-section">
				<div class="container">
					<div class="way-bg">
						<div class="way-shapes-img">
							<div class="way-shapes-left">
								<img src="@/assets/img/shape-06.png" alt="Image" />
							</div>
							<div class="way-shapes-right">
								<img src="@/assets/img/shape-07.png" alt="Image" />
							</div>
						</div>
						<div class="row align-items-end">
							<div class="col-lg-7 col-md-12">
								<div class="section-inner-header way-inner-header mb-0">
									<h2 class="text-white">Start Untangling the Medication Mess Today!</h2>
									<p>
										Healthcare providers have long struggled to find the time and resources to serve their patients or members as they truly desire. It’s time for a new approach. Partner with Consana Health, where our commitment to patient care and innovative solutions empower you to deliver the exceptional service you’ve always envisioned. Schedule a consultation to discover how we can transform your practice and enhance patient outcomes.
									</p>
									<router-link to="/contact-us" class="btn btn-primary"
										>Contact Us</router-link
									>
								</div>
							</div>
							<div class="col-lg-5 col-md-12">
								<div class="way-img">
									<img
										src="@/assets/img/way-img.png"
										class="img-fluid"
										alt="Image"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Way Choose Us -->
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
export default {
	mounted() {
		this.$nextTick(() => {
			AOS.init();
		});
	},
};
</script>
