<template>
  <!-- Benefits Section -->
  <section class="benefits-section mt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="row mt-2">
            <div class="col-4">
              <img src="@/assets/img/doctor-patient-records.jpg"
                   class="img-fluid"
                   alt="Image" />
            </div>
            <div class="col-8 px-5 section-header-one">
              <h2 class="section-title">
                Overwhelmed with Members, Limited Time?
              </h2>
              <p style="text-align: justify">
                Organizations have grappled with CMR completion challenges for years.
                It&rsquo;s time for a fresh perspective and collaboration with a
                value-based provider that prioritizes patients. Partner with Consana Health,
                where a unique patient-first mindset and a team of trusted clinical
                pharmacists ensure unparalleled, timely access to patient health
                information. Discover a new approach to medication management.
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-8 px-5 section-header-one">
              <h2 class="section-title">Reduce Readmissions</h2>
              <p style="text-align: justify">
                Preventable readmissions linked to medication issues are
                inevitable without prompt and thorough medication
                reconciliation post-discharge. It's disheartening to
                recognize the solution without the tools to implement it
                effectively. Empower your efforts with Consana Health &ndash; a
                proven solution that makes a significant impact.
              </p>
            </div>
            <div class="col-4">
              <img src="@/assets/img/patient-gardening.jpg"
                   class="img-fluid"
                   alt="Image" />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <img src="@/assets/img/woman-phone-laptop.jpg"
                   class="img-fluid"
                   style="transform: scaleX(-1)"
                   alt="Image" />
            </div>
            <div class="col-8 px-5 section-header-one">
              <h2 class="section-title">
                Increase Quality Ratings, like Medicare Stars and HEDIS
              </h2>
              <p style="text-align: justify">
                Collaborate with an innovative medication management service that alleviates
                the patient engagement workload for providers and staff. With a dedicated member
                audience and ongoing monitoring, Consana Health addresses a spectrum of concerns,
                including triple-weighted adherence, statin gap closure, and MTM CMR, ensuring
                seamless transitions of care. Partner for comprehensive medication support.
              </p>
              <p style="line-height: 100%">
                <small>
                  <em>
                    * HEDIS® is a registered trademark of the National
                    Committee for Quality Assurance (<a href="https://www.ncqa.org/hedis/"
                                                        target="_blank">NCQA</a>).
                  </em>
                </small>
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-8 px-5 section-header-one">
              <h2 class="section-title">Excel Where it Matters the Most</h2>
              <p style="text-align: justify">
                Feel the pressure to meet transitions of care measurement
                standards and engage every patient through medication
                reconciliation post-discharge? You don&rsquo;t have to
                navigate this challenge solo. Consana Health offers a distinctive
                blend of clinical pharmacist support and extensive patient
                information access, enabling improved performance metrics
                with minimal risk to your organization. Partner with Consana Health
                for success.
              </p>
            </div>
            <div class="col-4">
              <img src="@/assets/img/clinician-reconciliation.jpg"
                   class="img-fluid"
                   alt="Image" />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <img src="@/assets/img/patient-with-meds.jpg"
                   class="img-fluid"
                   style="transform: scaleX(-1)"
                   alt="Image" />
            </div>
            <div class="col-8 px-5 section-header-one">
              <h2 class="section-title">
                Achieve Quadruple-Weighted CAHPS Performance
              </h2>
              <p style="text-align: justify">
                As patient satisfaction gains more weight in Stars, staying
                ahead is crucial. Consana Health is your solution, seamlessly
                connecting with members under your brand, eliminating
                medication access barriers, synchronizing medications, and
                addressing social determinants of health. Our white-glove
                service intervenes precisely when members need us &ndash;
                the type of service that yields robust CAHPS results.
                Elevate your Stars rating with our comprehensive approach.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Benefits Section -->
</template>
<script>
  import AOS from "aos";
  import "aos/dist/aos.css";
  import IndexApp from "@/assets/json/indexapp.json";
  export default {
    data() {
      return {
        IndexApp: IndexApp,
      };
    },
    mounted() {
      this.$nextTick(() => {
        AOS.init();
      });
    },
  };
</script>
