<template>
	<div class="main-wrapper">
		<layout-header></layout-header>
		<page-banner :title="title" :text="text" />

		<div class="page-wrapper">
			<!-- Page Content -->
			<section class="content">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<h5>Blank Page</h5>
						</div>
					</div>
				</div>
			</section>
			<!-- /Page Content -->
		</div>
	</div>
	<page-footer />
</template>

<script>
export default {
	data() {
		return {
			title: "Blank Page",
			text: "Home",
		};
	},
};
</script>
